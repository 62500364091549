@media (min-width: 768px) {
}

@media (max-width: 768px) {
}

.cv {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  /* border: 1px solid black; */
}

.cv h1 {
  font-weight: 300;
  color: #393e46;
  margin-top: 45px;
}

.cv p {
  font-weight: 400;
  color: #222831;
  line-height: 160%;
  margin-left: 10px;
}
