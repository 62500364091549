@media (min-width: 992px) {
  .large-image {
    max-width: 50%;
  }
  .top-left {
    font-size: 25px;
    justify-self: center;
    padding-top: 20px;
    padding-left: 200px;
  }
  .top-right {
    font-size: 25px;
    justify-self: center;
    padding-top: 20px;
    padding-right: 120px;
  }
  .bottom-left {
    font-size: 20px;
    justify-self: center;
    padding-left: 100px;
  }
  .bottom-right {
    font-size: 20px;
    justify-self: center;
  }
  .arrow {
    width: 50px;
    height: 50px;
    font-size: 20px;
  }
}

@media (max-width: 992px) and (min-width: 768px) {
  .large-image {
    max-width: 60%;
  }
  .top-left {
    font-size: 20px;
    justify-self: center;
    padding-top: 20px;
    padding-left: 50px;
  }
  .top-right {
    font-size: 20px;
    justify-self: center;
    padding-top: 20px;
    padding-right: 30px;
  }
  .bottom-left,
  .bottom-right {
    font-size: 14px;
    justify-self: center;
  }
  .arrow {
    width: 50px;
    height: 50px;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .large-image {
    max-width: 100%;
  }
  .top-left,
  .top-right {
    font-size: 14px;
    padding-top: 14px;
  }
  .bottom-left,
  .bottom-right {
    font-size: 10px;
  }
  .arrow {
    width: 30px;
    height: 30px;
    font-size: 10px;
  }
}

.image-container {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr;
  grid-template-rows: 1fr auto 1fr;
  margin: 60px 0 60px 0;
  padding: 10px 10px 0px 10px;
  /* border: solid black 1px; */
}

.large-image {
  grid-column: 1 / 5;
  grid-row: 2;
  max-height: 70vh;
  object-fit: contain;
  border: 4px solid #222831;
  justify-self: center;
  /* border: solid black 1px; */
}

.top-left {
  grid-row: 1;
  grid-column: 1 / 3;
  text-align: left;
  color: #222831;
  font-weight: 500;
}

.top-right {
  grid-row: 1;
  grid-column: 3 / 5;
  text-align: right;
  color: #222831;
  font-weight: 300;
}

.bottom-left {
  grid-row: 3;
  grid-column: 1 / 3;
  text-align: left;
  color: #222831;
  font-weight: 300;
  font-style: italic;
}

.bottom-right {
  grid-row: 3;
  grid-column: 3 / 5;
  text-align: right;
  color: #222831;
  font-weight: 300;
}

.arrow {
  background-color: rgba(0, 0, 0, 1);
  color: #eeeeee;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 45%;
  transition: background-color 0.3s ease;
  z-index: 1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

.prev {
  left: 20px;
}

.next {
  right: 20px;
}
