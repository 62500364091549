@media (min-width: 768px) {
}

@media (max-width: 768px) {
}

.contact {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  /* border: 1px solid black; */
}

.contact h1 {
  font-weight: 300;
  color: #393e46;
  margin-top: 45px;
}

.contact p {
  font-weight: 400;
  color: #222831;
  line-height: 140%;
}
