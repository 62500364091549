@media (min-width: 992px) {
  .card-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .card p {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .card-grid {
    grid-template-columns: 1fr;
  }
  .card p {
    font-size: 12px;
  }
}

.card-grid {
  display: grid;
  margin: 12px 12px 80px 12px;
  gap: 20px;
  /* border: black solid 1px; */
}

.card {
  padding: 0 14px 10px 6px;
  cursor: pointer;
  /* border: black solid 1px; */
}

.card-extra-margin {
  margin: 40px;
}

.card img {
  border: 4px solid #222831;
  max-width: 100%;
  max-height: 100%;
}

.card .card-title {
  margin: 0;
  padding: 0;
  float: left;
  font-weight: 500;
}

.card .card-desc {
  margin: 0;
  padding: 0;
  float: right;
  font-weight: 300;
}

.card p {
  color: #222831;
}
