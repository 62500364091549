@media (min-width: 768px) {
}

@media (max-width: 768px) {
}

.press {
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  /* border: 1px solid black; */
}

.press h1 {
  font-weight: 300;
  color: #393e46;
  margin-top: 45px;
}

.press a {
  font-weight: 400;
  margin-left: 10px;
  line-height: 200%;
}

.press h2 {
  font-weight: 300;
  color: #393e46;
  margin-top: 30px;
}

.press p {
  font-weight: 400;
  color: #222831;
  line-height: 180%;
}
